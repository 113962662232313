import * as campaignActionType from "../../actions/action.campaign/action.type";
const campaign = (
  //initialise the initial state
  state = {
    //common error and status
    error: null,
    status: null,
    getallcamp_error: null,
    getallcamp_status: null,
    //campaign

    //create service
    service: null,
    create_service: null,
    //create&get calls campaign
    create_campaign: null,
    get_all_campaign: [],
     all_campaign: null,


    campaign_by_id: [],
    campaign_by_id_loading: false,
    campaign_by_id_error: null,
    campaign_enable_disable_status: null,
    update_camp_details: null,
    update_camp_qns: null,

    //put calls

  
    
  },
  action
) => {
  switch (action.type) {
    
    //campaign reducers type
    case campaignActionType.CREATE_SERVICE_FAILURE:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });

    case campaignActionType.CREATE_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        create_service: action.result,
      });
    case campaignActionType.CREATE_SERVICE_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });

    //create new campaign
    case campaignActionType.CREATE_CAMPAIGN_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case campaignActionType.CREATE_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        create_campaign: action.result,
      });
    case campaignActionType.CREATE_CAMPAIGN_ERROR:
      return Object.assign({}, state, {
        getallcamp_status: action.status,
        getallcamp_error: action.error,
      });
     
      // all campaigns get
      case campaignActionType.GET_ALL_CAMPAIGN_REQ:
        return Object.assign({}, state, {
          status: action.status,
        });
      case campaignActionType.GET_ALL_CAMPAIGN_SUCC:
        return Object.assign({}, state, {
          status: action.status,
          all_campaign: action.result,
        });
      case campaignActionType.GET_ALL_CAMPAIGN_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });

      // get campaigns by id 
      case campaignActionType.GET_CAMP_BY_ID_REQ:
      return Object.assign({}, state, {
        campaign_by_id: [],
        campaign_by_id_loading: true,
        campaign_by_id_error: null,
      });
    case campaignActionType.GET_CAMP_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        campaign_by_id: action.payload,
        campaign_by_id_loading: false,
        campaign_by_id_error: null,
      });
    case campaignActionType.GET_CAMP_BY_ID_ERR:
      return Object.assign({}, state, {
        campaign_by_id: [],
        campaign_by_id_loading: false,
        campaign_by_id_error: action.payload,
      });
      // update enable disable campaign status 
      case campaignActionType.PUT_CAMPAIGN_STATUS_BY_ID_REQ:
        return Object.assign({}, state, {
          status: action.status,
          
        });
      case campaignActionType.PUT_CAMPAIGN_STATUS_BY_ID_SUCC:
        return Object.assign({}, state, {
          status: action.status,
          campaign_enable_disable_status:action.payload,
        });
      case campaignActionType.PUT_CAMPAIGN_STATUS_BY_ID_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });

          // UPDATE campaign DETAILS
    case campaignActionType.PUT_CAMP_DETAILS_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case campaignActionType.PUT_CAMP_DETAILS_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        update_camp_details: action.result,
      });
    case campaignActionType.PUT_CAMP_DETAILS_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


         // UPDATE campaign QNS
    case campaignActionType.PUT_CAMP_QNS_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case campaignActionType.PUT_CAMP_QNS_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        update_camp_qns: action.result,
      });
    case campaignActionType.PUT_CAMP_QNS_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });

    //DEFAULT BEHAVIOR
    default:
      return state;
  }
};
export default campaign;

const setReducer = (state, newState) => Object.assign({}, state, newState);
