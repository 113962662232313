//CREATE GROUP
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

//GET ALL  CAMPAIGN
export const GET_ALL_GROUP_REQ = "GET_ALL_GROUP_REQ";
export const GET_ALL_GROUP_ERR = "GET_ALL_GROUP_ERR";
export const GET_ALL_GROUP_SUCC = "GET_ALL_GROUP_SUCC";

//get campaign by id
export const GET_GROUP_BY_ID_REQ = "GET_GROUP_BY_ID_REQ";
export const GET_GROUP_BY_ID_ERR = "GET_GROUP_BY_ID_ERR";
export const GET_GROUP_BY_ID_SUCCESS = "GET_GROUP_BY_ID_SUCCESS";

// ENABLE_DISABLE CAMPAIGN
export const PUT_GROUP_STATUS_BY_ID_REQ = "PUT_GROUP_STATUS_BY_ID_REQ";
export const PUT_GROUP_STATUS_BY_ID_ERR = "PUT_GROUP_STATUS_BY_ID_ERR";
export const PUT_GROUP_STATUS_BY_ID_SUCC = "PUT_GROUP_STATUS_BY_ID_SUCC";

//CREATE CONTACT
export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_ERROR = "CREATE_CONTACT_ERROR";

//UPDATE CONTACTS
export const PUT_CONTACT_REQ = "PUT_CONTACT_REQ";
export const PUT_CONTACT_SUCC = "PUT_CONTACT_SUCC";
export const PUT_CONTACT_ERR = "PUT_CONTACT_ERR";


//UPDATE GROUP DETAILS
export const PUT_GROUP_DETAILS_REQ = "PUT_GROUP_DETAILS_REQ";
export const PUT_GROUP_DETAILS_SUCC = "PUT_GROUP_DETAILS_SUCC";
export const PUT_GROUP_DETAILS_ERR = "PUT_GROUP_DETAILS_ERR";

//GET CONTACTS by GROUP ID
export const GET_CONTACTS_BY_GROUP_ID_REQ = "GET_CONTACTS_BY_GROUP_ID_REQ";
export const GET_CONTACTS_BY_GROUP_ID_ERR = "GET_CONTACTS_BY_GROUP_ID_ERR";
export const GET_CONTACTS_BY_GROUP_ID_SUCCESS = "GET_CONTACTS_BY_GROUP_ID_SUCCESS";
