import "isomorphic-fetch";
import { API_URL_ROOT } from "../Redux-service/actions/constance/action.constance";
import axios from "axios";

//common delete call
export async function Delete(url){
  console.log('deleting',url); 
  return await fetch(`${API_URL_ROOT}/${url}`,{
    method : "DELETE",
    headers : {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
    }
  });

}
//common post call
export async function post(url, payload) {
  console.log("posting ", url, payload);
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "POST",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      "content-type": "application/json",
      //Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
    },
    body: JSON.stringify(payload),
  });
}

//common put call
export async function put(url, payload) {
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      "Content-Type": "application/json",
    },
  });
}

//common get call
export async function get(url) {
  console.log("++++++++++++++++++", `${API_URL_ROOT}/${url}`);
  return await fetch(`${API_URL_ROOT}/${url}`, {
    //json: true,
    method: "GET",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      "content-type": "application/json",
    },
  });
}

//common patch call
export async function patch(url, payload) {
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "PATCH",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      //Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      "Content-Type": "application/json",
    },
  });
}

//common putImage call
export async function putImage(url, payload) {
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      //Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      "Content-Length": JSON.stringify(payload).length,
      keepAlive: true,
    },
  });
}

//common get call
export async function del(url) {
  console.log("++++++++++++++++++", `${API_URL_ROOT}/${url}`);
  return await fetch(`${API_URL_ROOT}/${url}`, {
    //json: true,
    method: "DELETE",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      "content-type": "application/json",
    },
  });
}

//common post call
export async function postWithAuth(url, payload) {
  console.log("posting ", url, payload);
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "POST",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
    },
    // body: JSON.stringify(payload),
  });
}

//common put call
export async function putWithAuth(url, payload) {
  console.log("++++++++++++++++++", `${API_URL_ROOT}/${url}`);
  console.log("++++++++++++++++++", `${JSON.stringify(payload)}`);

  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "PUT",
    mode: "cors",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  });
}

//common get call
export async function getWithAuth(url) {
  console.log("++++++++++++++++++", `${API_URL_ROOT}/${url}`);
  return await fetch(`${API_URL_ROOT}/${url}`, {
    //json: true,
    method: "GET",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  });
}

//common patch call
export async function patchWithAuth(url, payload) {
  return await fetch(`${API_URL_ROOT}/${url}`, {
    method: "PATCH",
    //crossDomain: true,
    mode: "cors",
    body: JSON.stringify(payload),
    json: true,
    headers: {
      "Access-Control-Allow-Origin":'*',
      "Access-Control-Allow-Methods": '*',
      "Authorization": `Bearer ${localStorage.getItem("token")}`,   
      "Content-Type": "application/json",
    },
  });
}

// post with form data
export async function postWithAuthFormData(url, payload) {
  console.log("++++++++++++++++++", `${API_URL_ROOT}/${url}`);
  console.log("posting ", url, payload);
  return await axios.post(`${API_URL_ROOT}/${url}`,payload,{ headers: {"Authorization" : `Bearer ${localStorage.getItem("token")}`} });
}
