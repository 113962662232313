import * as userActionType from "../../actions/action.users/action.type";
import { normalizeUser } from "../../../utils/selectors";
// default user status 
const defaultUser = {
  email: "",
  //username:"",
  id: null
};

const defaultState = {
  token: '',
  // roles: null,
  status: null,
  error: null,
  requesting: null,
  all_users: [],

  // create_user: null,
  // permissions: null,
  isLoggedIn: false,
  checkedForToken: false,

  


  user: { ...defaultUser }
}
const users = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    
   // login status of the admin  
    case userActionType.LOGIN_REQUEST:
      return Object.assign({}, state, {
        //status: null,
        error: null,
        requesting: true,
        isLoggedIn: false
      });
    case userActionType.LOGIN_FAILURE:
      return Object.assign({}, state, {
        //status: action.status,
        error: action.error,
        requesting: false,
        isLoggedIn: false
      });
    case userActionType.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        //status: action.status,
        user: action.result,
        isLoggedIn: true,
        requesting: false,
        error: null
      });


      //logout status of the admin
      case userActionType.LOGOUT_REQUEST:
        return Object.assign({}, state, {
          //status: null,
          error: null,
          requesting: true,
          isLoggedIn: true
        });
      case userActionType.LOGOUT_FAILURE:
        return Object.assign({}, state, {
          //status: action.status,
          error: "error occured",
          requesting: false,
          isLoggedIn: true
        });
      case userActionType.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          //status: action.status,
          user: { ...defaultUser },
          token:'',
          isLoggedIn: false,
          requesting: false,
          error: null
        });
  

      // addition of admin token to the redux state for accessing
    case userActionType.ADD_TOKEN_TO_STATE:
      return {
        ...state,
        token: action.payload
      }
      // verifying the admin by token
    case userActionType.QUERYING_USER_BY_TOKEN:
      return {
        ...state,
        pending: true,
        checkedForToken: true
      }
    case userActionType.QUERYING_USER_BY_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        isLoggedIn: true,
        user: normalizeUser(action.payload)
      }
    case userActionType.QUERYING_USER_BY_TOKEN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      }

      // updating the admin credential in the store
    case userActionType.UPDATE_USER_IN_STORE:
      return {
        ...state,
        user: normalizeUser({ ...state.user, ...action.payload })
      }

    //case userActionType:
    default:
      return state;

  }
}
export default users;
