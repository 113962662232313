import dashboard from "../reducers/reducer.dashboard/reducer.getcall";
import { combineReducers } from "redux";
import users from "../reducers/reducer.users/userReducer";
import group from "../reducers/reducer.groupcontact/contact";
import campaign from "../reducers/reducer.campaign/campaign";
import url from "../reducers/reducer.url/url";
import form from "../reducers/reducer.form/form";
import report from "../reducers/reducer.report/report";

// combining all the defined reducers in a single root reducer for the access
export const rootReducer = combineReducers({
  dashboard: dashboard,
  auth: users,
  group:group,
  campaign:campaign,
  url:url,
  form:form,
  report:report
});
