// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

// create url

export const CREATE_URL_REQUEST = "CREATE_URL_REQUEST";
export const CREATE_URL_SUCCESS = "CREATE_URL_SUCCESS";
export const CREATE_URL_ERROR = "CREATE_URL_ERROR";


// get url

export const GET_ALL_URL_REQ = "GET_ALL_URL_REQ";
export const GET_ALL_URL_ERR = "GET_ALL_URL_ERR";
export const GET_ALL_URL_SUCC = "GET_ALL_CAMPAIGN_SUCC";


// update url status 
export const PUT_URL_STATUS_BY_ID_REQ = "PUT_URL_STATUS_BY_ID_REQ";
export const PUT_URL_STATUS_BY_ID_ERR = "PUT_URL_STATUS_BY_ID_ERR";
export const PUT_URL_STATUS_BY_ID_SUCC = "PUT_URL_STATUS_BY_ID_SUCC";


