export const config_file = {
  development: {
    
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://feedback.landclues.com/backend",
  },
  production: {
    
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://feedback.landclues.com/backend",
  },
  staging: {
    // DOMAIN_NAME: "https://your.xyz.com",
    // API_DOMAIN_NAME: "https://alpha.xyz.com",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://feedback.landclues.com/backend",
  },
  test: {
    // DOMAIN_NAME: "http://localhost:3000",
    // API_DOMAIN_NAME: "http://localhost:4000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "https://feedback.landclues.com/backend",
  },
};
