import * as urlActionType from "../../actions/action.url/action.type";

const url= (
      //initialise the initial state
  state = {

    error: null,
    status: null,
    getallUrl_error: null,
    getallUrl_status: null,

    create_url: null,
    get_all_url: [],
     all_url: null,

     url_enable_disable_status: null,
  },
  action
) => {
    switch (action.type) {



         //create new url
    case urlActionType.CREATE_URL_REQUEST:
        return Object.assign({}, state, {
          status: action.status,
        });
      case urlActionType.CREATE_URL_SUCCESS:
        return Object.assign({}, state, {
          status: action.status,
          create_url: action.result,
        });
      case urlActionType.CREATE_URL_ERROR:
        return Object.assign({}, state, {
          getallUrl_status: action.status,
          getallUrl_error: action.error,
        });

        // get all url 

        case urlActionType.GET_ALL_URL_REQ:
            return Object.assign({}, state, {
              status: action.status,
            });
          case urlActionType.GET_ALL_URL_SUCC:
            return Object.assign({}, state, {
              status: action.status,
              all_url: action.result,
            });
          case urlActionType.GET_ALL_URL_ERR:
            return Object.assign({}, state, {
              status: action.status,
              error: action.error,
            });

              // update enable disable url 
            case urlActionType.PUT_URL_STATUS_BY_ID_REQ:
        return Object.assign({}, state, {
          status: action.status,
          
        });
      case urlActionType.PUT_URL_STATUS_BY_ID_SUCC:
        return Object.assign({}, state, {
          status: action.status,
          url_enable_disable_status:action.payload,
        });
      case urlActionType.PUT_URL_STATUS_BY_ID_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });

            //DEFAULT BEHAVIOR
    default:
        return state;
    }
};
export default url;

const setReducer = (state, newState) => Object.assign({}, state, newState);