/** write your api's here and export to respective actions */

// admin 
export const login_user = "auth/login";
export const logout_user = "auth/logout";


//campaigns
export const create_campaign = "formquestion/createform";
export const get_campaign_by_id = (campaign_id) => `formquestion/forms/${campaign_id}`;
export const get_all_campaign_service = "formquestion/forms";
export const update_campaign_details = (campaign_id) => `formquestion/editforms/${campaign_id}`;
export const update_campaign_qns = (campaign_id) => `formquestion/addextraquestions/${campaign_id}`;
export const enable_disable_campaign = (campaign_id) => `formquestion/enable-disble/${campaign_id}`;
//contact
export const create_group = "groups/createGroup";
export const get_all_group = "groups/viewAllGroup";
export const get_group_by_id = (group_id) => `groups/viewGroupById/${group_id}`;

export const enable_disable_group = (group_id) => `groups/disble/${group_id}`;

export const create_contact = "contact/createContact";

export const get_all_contacts_by_groupid = "contact/viewContactByGroupId";

export const update_group_details = (group_id) => `formquestion/editforms/${group_id}`;
export const update_contact = (group_id) => `contact/updateContact/${group_id}`;




// url 
export const get_all_url = "generateurl/geturllist";
export const generate_url = "generateurl/form";
export const enable_disable_url = (campaign_id) => `generateurl/urlenable-disble/${campaign_id}`;

// reports

export const get_all_campaign_report = "generateurl/reportlist";
export const get_campaign_report_by_id = (campaign_id) => `generateurl/report/${campaign_id}`;

export const get_campaign_report_by_id_descriptive = (campaign_id) => `generateurl/userratingcount/feedbacktype2/${campaign_id}`;
export const get_campaign_report_by_id_multiple_choice = (campaign_id) => `generateurl/userratingcount/feedbacktype3/${campaign_id}`;

export const get_all_user_ratings_campaign_report_by_id = (campaign_id) => `generateurl/useravgrating/${campaign_id}`;
export const get_all_user_ratings_details_campaign_report_by_id  = (campaign_id, mobileNo) => `generateurl/useravgrating/${campaign_id}/${mobileNo}`;

// dashboard
export const get_campaign_count = "dashboard/getcampaigncount";
export const get_question_review_count = "dashboard/getquestionreviewcount";
export const get_users_review_count = "dashboard/getusersreviewcount";

