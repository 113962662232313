import * as constance from "../constance/action.constance";
import {
  LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS,LOGOUT_FAILURE,
  
  
} from "./action.type";

export function login_req() {
  return {
    type: LOGIN_REQUEST,
    status: constance.REQUESTING
  };
}

export function login_fail(error) {
  return {
    type: LOGIN_FAILURE,
    error,
    status: constance.FAIL
  };
}

export function login_success(result) {
  return {
    type: LOGIN_SUCCESS,
    result,
    status: constance.SUCCESS
  };
}

//logout
export function logout_req() {
  return {
    type: LOGOUT_REQUEST,
    status: constance.REQUESTING
  };
}

export function logout_fail(error) {
  return {
    type: LOGOUT_FAILURE,
    error,
    status: constance.FAIL
  };
}

export function logout_success(result) {
  return {
    type: LOGOUT_SUCCESS,
    result,
    status: constance.SUCCESS
  };
}



