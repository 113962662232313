import * as groupActionType from "../../actions/action.groupcontact/action.type";
const group = (
  //initialise the initial state
  state = {
    //common error and status
    error: null,
    status: null,
    getallgroup_error: null,
    getallgroup_status: null,
    //campaign

    
    //create&get calls campaign
    create_group: null,
    get_all_group: [],
     all_group: null,


    group_by_id: [],
    group_by_id_loading: false,
    group_by_id_error: null,
    group_enable_disable_status: null,
    create_contact:null,
    
    all_contacts_by_groupid:[],
    all_contacts_by_groupid_loading: false,
    all_contacts_by_groupid_error: null,

     update_group_details: null,
     update_contact: null,

    //put calls

  
    
  },
  action
) => {
  switch (action.type) {
    
    // //campaign reducers type
    // case campaignActionType.CREATE_SERVICE_FAILURE:
    //   return Object.assign({}, state, {
    //     status: action.status,
    //     error: action.error,
    //   });

    // case campaignActionType.CREATE_SERVICE_SUCCESS:
    //   return Object.assign({}, state, {
    //     status: action.status,
    //     create_service: action.result,
    //   });
    // case campaignActionType.CREATE_SERVICE_REQUEST:
    //   return Object.assign({}, state, {
    //     status: action.status,
    //   });

    //create new campaign
    case groupActionType.CREATE_GROUP_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case groupActionType.CREATE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        create_group: action.result,
      });
    case groupActionType.CREATE_GROUP_ERROR:
      return Object.assign({}, state, {
        getallgroup_status: action.status,
        getallgroup_error: action.error,
      });
     
      // GET ALL GROUPS
      case groupActionType.GET_ALL_GROUP_REQ:
        return Object.assign({}, state, {
          status: action.status,
        });
      case groupActionType.GET_ALL_GROUP_SUCC:
        return Object.assign({}, state, {
          status: action.status,
          all_group: action.result,
        });
      case groupActionType.GET_ALL_GROUP_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });

      // get campaigns by id 
      case groupActionType.GET_GROUP_BY_ID_REQ:
      return Object.assign({}, state, {
        group_by_id: [],
        group_by_id_loading: true,
        group_by_id_error: null,
      });
    case groupActionType.GET_GROUP_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        group_by_id: action.payload,
        group_by_id_loading: false,
        group_by_id_error: null,
      });
    case groupActionType.GET_GROUP_BY_ID_ERR:
      return Object.assign({}, state, {
        group_by_id: [],
        group_by_id_loading: false,
        group_by_id_error: action.payload,
      });


      // get contacts by group_id
      case groupActionType.GET_CONTACTS_BY_GROUP_ID_REQ:
      return Object.assign({}, state, {
        all_contacts_by_groupid: [],
        all_contacts_by_groupid_loading: true,
        all_contacts_by_groupid_error: null,
      });
    case groupActionType.GET_CONTACTS_BY_GROUP_ID_SUCCESS:
      return Object.assign({}, state, {
        all_contacts_by_groupid: action.payload,
        all_contacts_by_groupid_loading: false,
        all_contacts_by_groupid_error: null,
      });
    case groupActionType.GET_CONTACTS_BY_GROUP_ID_ERR:
      return Object.assign({}, state, {
        all_contacts_by_groupid: [],
        all_contacts_by_groupid_loading: false,
        all_contacts_by_groupid_error: action.payload,
      });


      // update enable disable campaign status 
      case groupActionType.PUT_GROUP_STATUS_BY_ID_REQ:
        return Object.assign({}, state, {
          status: action.status,
          
        });
      case groupActionType.PUT_GROUP_STATUS_BY_ID_SUCC:
        return Object.assign({}, state, {
          status: action.status,
          group_enable_disable_status:action.payload,
        });
      case groupActionType.PUT_GROUP_STATUS_BY_ID_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });


         //create new contact
    case groupActionType.CREATE_CONTACT_REQUEST:
      return Object.assign({}, state, {
        status: action.status,
      });
    case groupActionType.CREATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        status: action.status,
        create_contact: action.result,
      });
    case groupActionType.CREATE_CONTACT_ERROR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });
      
         // UPDATE GROUP DETAILS
    case groupActionType.PUT_GROUP_DETAILS_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case groupActionType.PUT_GROUP_DETAILS_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        update_group_details: action.result,
      });
    case groupActionType.PUT_GROUP_DETAILS_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


         // UPDATE CONTACT
    case groupActionType.PUT_CONTACT_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case groupActionType.PUT_CONTACT_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        update_group_qns: action.result,
      });
    case groupActionType.PUT_CONTACT_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });


          


         

    //DEFAULT BEHAVIOR
    default:
      return state;
  }
};
export default group;

const setReducer = (state, newState) => Object.assign({}, state, newState);
