//get form by id
export const GET_FORM_BY_ID_REQ = "GET_FORM_BY_ID_REQ";
export const GET_FORM_BY_ID_ERR = "GET_FORM_BY_ID_ERR";
export const GET_FORM_BY_ID_SUCCESS = "GET_FORM_BY_ID_SUCCESS";

// post user phone number verification

export const POST_FORM_PHONE_VERIFY_REQ = "POST_FORM_PHONE_VERIFY_REQ";
export const POST_FORM_PHONE_VERIFY_ERR = "POST_FORM_PHONE_VERIFY_ERR";
export const POST_FORM_PHONE_VERIFY_SUCCESS = "POST_FORM_PHONE_VERIFY_SUCCESS";


// post user response 

export const POST_FORM_RESPONSE_REQ = "POST_FORM_RESPONSE_REQ";
export const POST_FORM_RESPONSE_ERR = "POST_FORM_RESPONSE_ERR";
export const POST_FORM_RESPONSE_SUCCESS = "POST_FORM_RESPONSE_SUCCESS";
