import * as formActionType from "../../actions/action.form/action.type";

const form= (
      //initialise the initial state
  state = {

    error: null,
    status: null,
    form_by_id: [],
    form_by_id_loading: false,
    form_by_id_error: null,
    form_report_response:null,
    form_report_response_error:null,
    form_report_phone_verify_response:null,
    form_report_phone_verify_response_error:null,
    
  },
  action
) => {
    switch (action.type) {
        // get form by id
        case formActionType.GET_FORM_BY_ID_REQ:
      return Object.assign({}, state, {
        form_by_id: [],
        form_by_id_loading: true,
        form_by_id_error: null,
      });
    case formActionType.GET_FORM_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        form_by_id: action.payload,
        form_by_id_loading: false,
        form_by_id_error: null,
      });
    case formActionType.GET_FORM_BY_ID_ERR:
      return Object.assign({}, state, {
        form_by_id: [],
        form_by_id_loading: false,
        form_by_id_error: action.payload,
      });

      // form response from user  
      case formActionType.POST_FORM_RESPONSE_REQ:
        return Object.assign({}, state, {
          status: action.status,
        });
      case formActionType.POST_FORM_RESPONSE_SUCCESS:
        return Object.assign({}, state, {
          form_report_response: action.payload,
          form_report_response_error: null,
        });
      case formActionType.POST_FORM_RESPONSE_ERR:
        return Object.assign({}, state, {
          form_report_response: null,
          form_report_response_error: action.payload,
        });


        // form response for user number verify   
      case formActionType.POST_FORM_PHONE_VERIFY_REQ:
        return Object.assign({}, state, {
          status: action.status,
        });
      case formActionType.POST_FORM_PHONE_VERIFY_SUCCESS:
        return Object.assign({}, state, {
          form_report_phone_verify_response: action.payload,
          form_report_phone_verify_response_error: null,
        });
      case formActionType.POST_FORM_PHONE_VERIFY_ERR:
        return Object.assign({}, state, {
          form_report_phone_verify_response: null,
          form_report_phone_verify_response_error: action.payload,
        });

        //DEFAULT BEHAVIOR
    default:
        return state;
    }

};

export default form;
