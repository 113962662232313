import React, { Component } from "react";
import { HashRouter,BrowserRouter, Route, Switch } from "react-router-dom";
 import "./App.scss";
import CustomRoute from "./utils/CustomRoute";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));


// Pages
const Login = React.lazy(() => import("./views/Users/Login"));
//contact
const  Contact= React.lazy(() => import("./views/Contact/Contact"));
const  CreateGroup= React.lazy(() =>
  import("./views/Contact/CreateGroup")
);
const AddContacts = React.lazy(() =>
  import("./views/Contact/AddContacts")
);
const EditGroup = React.lazy(() =>
  import("./views/Contact/EditGroup")
);
const ViewGroup  = React.lazy(() =>
  import("./views/Contact/ViewGroup")
);

const AllContacts = React.lazy(() =>
  import("./views/Contact/AllContacts")
);




//Campaign
const Manage = React.lazy(() => import("./views/Campaign/Manage/Manage"));
const AddCampaign = React.lazy(() =>
  import("./views/Campaign/Manage/AddCampaign")
);

const MultilingualAddCampaign =  React.lazy(() =>
import("./views/Campaign/Manage/MultilingualAddCampaign")
);
const EnglishAddCampaign =  React.lazy(() =>
import("./views/Campaign/Manage/EnglishAddCampaign")
);
const KannadaAddCampaign =  React.lazy(() =>
import("./views/Campaign/Manage/KannadaAddCampaign")
);
const EditCampaign = React.lazy(() =>
  import("./views/Campaign/Manage/EditCampaign")
);
const ViewCampaign = React.lazy(() =>
  import("./views/Campaign/Manage/ViewCampaign")
);
const Page400 = React.lazy(() => import("./views/Pages/Page400"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const Dashboard = React.lazy(() => import("./views/Dashboard"));
// URLs
const GenerateUrl = React.lazy(() => import("./views/Url/GenerateUrl"));
const ListUrl = React.lazy(() => import("./views/Url/ListUrl"));
const SendUrl = React.lazy(() => import("./views/Url/SendUrl"));

 const Form = React.lazy(() => import("./views/UserFormView/Form"));
 const ForgetPassword = React.lazy(() => import("./views/Users/ForgotPassword"));
  const ManageReports = React.lazy(() => import("./views/Reports/ManageReports"));
  const ViewReports = React.lazy(() => import("./views/Reports/ViewReports"));
  const IndividualReports = React.lazy(() => import("./views/Reports/IndividualReports"));


  const DescriptiveIndividualReports = React.lazy(() => import("./views/Reports/DescriptiveIndividualReports"));
  const MultipleChoiceIndividualReports = React.lazy(() => import("./views/Reports/MultipleChoiceIndividualReports"));
  const IndividualReportModal = React.lazy(() => import("./views/Reports/IndividualReportModal"));

  const ChangePassword = React.lazy(() => import("./views/ChangePassword/ChangePassword"));


const pageRoutes = [{}];
class App extends Component {
  render() {
    return (
      // <HashRouter>
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            
            <Route
              exact path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact path="/400"
              name="Page 400"
              render={(props) => <Page400 {...props} />}
            />
            <Route
              exact path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            {/* user form  */}
             <Route exact path="/generateurl/:campaign_id" name="User Form" component={Form} />
              {/* Forget Password */}
             <Route 
             //exact path="/forgetPassword"
             exact path="/resetpassword/:user_id/:token"  
             name="Forget Password" 
             component={ForgetPassword} />
             {/* Default Layout  */}
            <CustomRoute
              protectedPath
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
            {/* dashboard */}
            <CustomRoute
              protectedPath
              name="Dashboard"
              path="/dashboard"
              render={(props) => <Dashboard {...props} />}
            />
             {/* Contact*/}

             <CustomRoute
              protectedPath
              path="/Contact"
              name="Contact"
              
              render={(props) => <Contact {...props} />}
            />
             <CustomRoute
              protectedPath
              path="/Contact/CreateGroup"
              name="CreateGroup"
              
              render={(props) => <CreateGroup {...props} />}
              
            />
             <CustomRoute
              protectedPath
              path="/Contact/AllContacts"
              name="AllContacts"
              
              render={(props) => <AllContacts{...props} />}
              
            />
            <CustomRoute
              protectedPath
              path="/Contact/AddContacts"
              name="AddContacts"
              
              render={(props) => <AddContacts{...props} />}
              
            />

            
            <CustomRoute
              protectedPath
              path="/Contact/EditGroup"
              name="EditGroup"
              
              render={(props) => <EditGroup {...props} />}
            />
            <CustomRoute
              protectedPath
              path="/contact/ViewGroup"
              name="ViewGroup"
              
              render={(props) => <ViewGroup {...props} />}
            />

            
           

            {/* Campaign */}

            <CustomRoute
              protectedPath
              path="/Campaign"
              name="Manage"
              
              render={(props) => <Manage {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Campaign/Manage/AddCampaign"
              name="AddCampaign"
              
              render={(props) => <AddCampaign {...props} />}
            />
             <CustomRoute
              protectedPath
              path="/Campaign/Manage/MultilingualAddCampaign"
              name="MultilingualAddCampaign"
              
              render={(props) => <MultilingualAddCampaign {...props} />}
            />
             <CustomRoute
              protectedPath
              path="/Campaign/Manage/EnglishAddCampaign"
              name="EnglishAddCampaign"
              
              render={(props) => <EnglishAddCampaign {...props} />}
            />
            <CustomRoute
              protectedPath
              path="/Campaign/Manage/KannadaAddCampaign"
              name="KannadaAddCampaign"
              
              render={(props) => <KannadaAddCampaign {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Campaign/Manage/EditCampaign"
              name="EditCampaign"
              
              render={(props) => <EditCampaign {...props} />}
            />
            <CustomRoute
              protectedPath
              path="/Campaign/Manage/ViewCampaign/:campaign_id"
              name="ViewCampaign"
              
              render={(props) => <ViewCampaign {...props} />}
            />
            {/* URL  */}
            <CustomRoute
              protectedPath
              path="/Url/GenerateUrl"
              name="GenerateUrl"
              
              render={(props) => <GenerateUrl {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Url/ListUrl"
              name="ListUrl"
              
              render={(props) => <ListUrl {...props} />}
            />
             <CustomRoute
              protectedPath
              path="/Url/SendUrl"
              name="SendUrl"
              
              render={(props) => <SendUrl {...props} />}
            />

             {/* Reports */}

             <CustomRoute
              protectedPath
              path="/Reports"
              name="ManageReports"
              
              render={(props) => <ManageReports {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Reports/ViewReports/:campaign_id"
              name="ViewReports"
              
              render={(props) => <ViewReports {...props} />}
            />

              <CustomRoute
              protectedPath
              path="/Reports/IndividualReports/:campaign_id"
              name="IndividualReports"
              
              render={(props) => <IndividualReports {...props} />}
            />
            <CustomRoute
              protectedPath
              path="/Reports/DescriptiveIndividualReports/:question_id"
              name="DescriptiveIndividualReports"
              
              render={(props) => <DescriptiveIndividualReports {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Reports/MultipleChoiceIndividualReports/:question_id"
              name="MultipleChoiceIndividualReports"
              
              render={(props) => <MultipleChoiceIndividualReports {...props} />}
            />

            <CustomRoute
              protectedPath
              path="/Reports/IndividualReportModal/:campaign_id/:mobileNo"
              name="IndividualReportModal"
              
              render={(props) => <IndividualReportModal {...props} />}
            />
           
           {/* ChangePassword */}

           <CustomRoute
              protectedPath
              name="ChangePassword"
              path="/ChangePassword"
              render={(props) => <ChangePassword {...props} />}
            />


          </Switch>
        </React.Suspense>
        </BrowserRouter>
      // </HashRouter>
    );
  }
}

export default App;
