// /*define your type here, so that you can use it in action */

//Example format
// export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
// export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
// export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

//create new service 
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";



export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";

//GET ALL  CAMPAIGN
export const GET_ALL_CAMPAIGN_REQ = "GET_ALL_CAMPAIGN_REQ";
export const GET_ALL_CAMPAIGN_ERR = "GET_ALL_CAMPAIGN_ERR";
export const GET_ALL_CAMPAIGN_SUCC = "GET_ALL_CAMPAIGN_SUCC";

//get campaign by id
export const GET_CAMP_BY_ID_REQ = "GET_CAMP_BY_ID_REQ";
export const GET_CAMP_BY_ID_ERR = "GET_CAMP_BY_ID_ERR";
export const GET_CAMP_BY_ID_SUCCESS = "GET_CAMP_BY_ID_SUCCESS";

// ENABLE_DISABLE CAMPAIGN
export const PUT_CAMPAIGN_STATUS_BY_ID_REQ = "PUT_CAMPAIGN_STATUS_BY_ID_REQ";
export const PUT_CAMPAIGN_STATUS_BY_ID_ERR = "PUT_CAMPAIGN_STATUS_BY_ID_ERR";
export const PUT_CAMPAIGN_STATUS_BY_ID_SUCC = "PUT_CAMPAIGN_STATUS_BY_ID_SUCC";

//UPDATE QNS CAMPAIGN
export const PUT_CAMP_QNS_REQ = "PUT_CAMP_QNS_REQ";
export const PUT_CAMP_QNS_SUCC = "PUT_CAMP_QNS_SUCC";
export const PUT_CAMP_QNS_ERR = "PUT_CAMP_QNS_ERR";

//UPDATE CAMPAIGN DETAILS
export const PUT_CAMP_DETAILS_REQ = "PUT_CAMP_DETAILS_REQ";
export const PUT_CAMP_DETAILS_SUCC = "PUT_CAMP_DETAILS_SUCC";
export const PUT_CAMP_DETAILS_ERR = "PUT_CAMP_DETAILS_ERR";


//UPDATE CAMPAIGN DETAILS
export const PUT_CAMP_CAMPAIGN_REQ = "PUT_CAMP_CAMPAIGN_REQ";
export const PUT_CAMP_CAMPAIGN_SUCC = "PUT_CAMP_CAMPAIGN_SUCC";
export const PUT_CAMP_CAMPAIGN_ERR = "PUT_CAMP_CAMPAIGN_ERR";








