import { 
  login_req, 
  login_fail, 
  login_success, 
  
} from './action.set.type';
import { 
  login_user,  
  } from '../../api/api';

import decodeJwt from "jwt-decode";

import { 
    ADD_TOKEN_TO_STATE, 
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    QUERYING_USER_BY_TOKEN,
    QUERYING_USER_BY_TOKEN_ERROR, 
    QUERYING_USER_BY_TOKEN_SUCCESS 
} from './action.type';

import {  post} from '../../../utils/apiRequest';

import { push } from "connected-react-router"
import { toast } from "react-toastify"


import toaster from "../../../utils/ReactTostify";


export function createUser(payload) {
  return async (dispatch, getState) => {
    dispatch()
  }
}

// login for admin

export const login = (payload) => {
  return async (dispatch, getState) => {
    dispatch(login_req());

    try {
      const { username, password } = payload;
      const result = await post(login_user, { username:username, password:password });
      const  user  = await result.json();
      console.log("user values === ", user);
      if (result.status === 200) {
        localStorage.setItem("token", user.token);
        dispatch(addTokenToState());
        dispatch(login_success(user));
        toaster('success', 'Logged in successfully');
      } else {
        //const error = await result.json();
        dispatch(login_fail(user))
        toaster('error', "Login failed");
      }
    } catch (err) {
      dispatch(login_fail(err));
      toaster('error', err);
    }
  }
}

//logout admin
export const logout = () => dispatch => {
  // log out backwoods
  
  // unlink third-party account
  dispatch({ type: LOGOUT_REQUEST });
  try{
    localStorage.removeItem('token')
    dispatch({ type: LOGOUT_SUCCESS })
    dispatch(push("/login"))
    //localStorage.removeItem("token")
    toast.success("Log out successful", {
      position: toast.POSITION.BOTTOM_RIGHT
    })
    
  }
  catch(err) {
    console.log(err)
    toast.error("Error when unlink your account", {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
  dispatch(push("/login"));
}



export const addTokenToState = () => dispatch => {
  let token
  try {
    token = localStorage.getItem("token")
  } catch (e) {
    console.log("ADD TOKEN TO STATE ERROR:", e)
  }
  // If no token, bail out:
  if (!token) return
  dispatch({ type: ADD_TOKEN_TO_STATE, payload: token })
  // Use token to check DB for user:
  dispatch(checkDbForUser(token))
}




// admin access based on adminid
export const checkDbForUser = token => dispatch => {
  dispatch({ type: QUERYING_USER_BY_TOKEN })
  const { id, exp } = decodeJwt(token)
  if (!id)
    return dispatch({
      type: QUERYING_USER_BY_TOKEN_ERROR,
      payload: `Token invalid: ${token}`
    })
  if (Date.now() >= exp * 10) {
    console.log('--------token and id-----',token, id);
    dispatch(push("/login"))
  }
console.log('--------token and id-----',token, id)
  // getWithAuth(getUserById(id), {
  //   headers: {
  //     Authorization: `Bearer ${token}`
  //   }
  // }).then(res => res.json())
  //   .then(response => {
  //      console.log('-------------get OOOOOOOO-----', response)
  //     dispatch({ type: QUERYING_USER_BY_TOKEN_SUCCESS, payload: response })
  //     dispatch(push("/dashboard"))
      
  //   })
  //   .catch(err => {
  //     dispatch({
  //       type: QUERYING_USER_BY_TOKEN_ERROR,
  //       payload: err
  //     })
  //     toast.error(err, {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     })
  //   })
}

