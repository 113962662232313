import { NotListedLocation, NotListedLocationOutlined } from '@material-ui/icons';
import * as dashboardActionType  from '../../actions/action.dashboard/action.type';
const dashboard = (
    //initialize initial state
     state = {
        dashboard: [],
        error: null,
        status: null,
         dashboard_sidebar:null,
         campaign_count:null,
         user_review_count:null,
         question_count:null

    },
    action
) => {
 switch (action.type) {
     //dashboard reducers
     case dashboardActionType.DASHBOARD_REQUEST : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.DASHBOARD_FAILURE : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.DASHBOARD_SUCCESS : 
     return Object.assign({}, state, {
         status : action.status,
         dashboard : action.matches,
         dashboard_sidebar: action.result
     })

     // campaign state 
     case dashboardActionType.CAMP_STATE_REQ:
        return Object.assign({}, state, {
          status: action.status,
        });
      case dashboardActionType.CAMP_STATE_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        hospital_sidebar: action.result,
      });
      case dashboardActionType.CAMP_STATE_ERR:
        return Object.assign({}, state, {
          status: action.status,
          error: action.error,
        });


        // get campaign count
        case dashboardActionType.GET_CAMPAIGN_COUNT_REQ : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.GET_CAMPAIGN_COUNT_ERR : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.GET_CAMPAIGN_COUNT_SUCC : 
     return Object.assign({}, state, {
         status : action.status,
         campaign_count: action.result
     })


     // question review count
     case dashboardActionType.GET_QUESTION_REVIEW_COUNT_REQ : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.GET_QUESTION_REVIEW_COUNT_ERR : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.GET_QUESTION_REVIEW_COUNT_SUCC : 
     return Object.assign({}, state, {
         status : action.status,
         question_count: action.result
     })

     // get user review count 
     case dashboardActionType.GET_USERS_REVIEW_COUNT_REQ : 
     return Object.assign({}, state, {
         status : action.status
     })
     
     case dashboardActionType.GET_USERS_REVIEW_COUNT_ERR : 
     return Object.assign(
         {}, 
        state, {
         status : action.status
     }
     )
      
     case dashboardActionType.GET_USERS_REVIEW_COUNT_SUCC : 
     return Object.assign({}, state, {
         status : action.status,
         user_review_count: action.result
     })

    //default behavior
     default : 
     return state;
 };

}

export default dashboard;

