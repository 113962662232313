//GET ALL CAMPAIGN REPORTS
export const GET_ALL_CAMPAIGN_REPORT_REQ = "GET_ALL_CAMPAIGN_REPORT_REQ";
export const GET_ALL_CAMPAIGN_REPORT_ERR = "GET_ALL_CAMPAIGN_REPORT_ERR";
export const GET_ALL_CAMPAIGN_REPORT_SUCC = "GET_ALL_CAMPAIGN_REPORT_SUCC";

//get campaign reports by id
export const GET_CAMP_REPORT_BY_ID_REQ = "GET_CAMP_REPORT_BY_ID_REQ";
export const GET_CAMP_REPORT_BY_ID_ERR = "GET_CAMP_REPORT_BY_ID_ERR";
export const GET_CAMP_REPORT_BY_ID_SUCCESS = "GET_CAMP_REPORT_BY_ID_SUCCESS";

// descriptive reports
export const GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_REQ = "GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_REQ";
export const GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_ERR = "GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_ERR";
export const GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_SUCCESS = "GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_SUCCESS";

// multiple choice reports 

export const GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_REQ = "GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_REQ";
export const GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_ERR = "GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_ERR";
export const GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_SUCCESS = "GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_SUCCESS";

//get all user ratings campaign reports by id 
export const GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_REQ = "GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_REQ";
export const GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_ERR = "GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_ERR";
export const GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_SUCCESS = "GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_SUCCESS";


//get all user ratings details campaign reports by id 
export const GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_REQ = "GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_REQ";
export const GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_ERR = "GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_ERR";
export const GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_SUCCESS = "GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_SUCCESS";