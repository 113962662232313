import * as reportActionType from "../../actions/action.report/action.type";

const report = (
    //initialise the initial state
    state = {
        //common error and status
    error: null,
    status: null,
    getallcampreport_error: null,
    getallcampreport_status: null,

    // all campaign report
    get_all_campaign_report: [],
    all_campaign_report: null,
    
    // campaign reports by id
    campaign_report_by_id: [],
    campaign_report_by_id_loading: false,
    campaign_report_by_id_error: null,

    // campaign reports by id descriptive
    campaign_report_by_id_descriptive: [],
    campaign_report_by_id_descriptive_loading: false,
    campaign_report_by_id_descriptive_error: null,

    // campaign reports by id multiple choice
    campaign_report_by_id_multiple_choice: [],
    campaign_report_by_id_multiple_choice_loading: false,
    campaign_report_by_id_multiple_choice_error: null,

    // all users ratings of inidividual campaign reports 
    all_user_ratings_campaign_report_by_id: [],
    all_user_ratings_campaign_report_by_id_loading: false,
    all_user_ratings_campaign_report_by_id_error: null,

    // detailed retings report by the user of individual campaign reports
    all_user_ratings_details_campaign_report_by_id: [],
    all_user_ratings_details_campaign_report_by_id_loading: false,
    all_user_ratings_details_campaign_report_by_id_error: null,
},
action
) => {
switch (action.type) {

    // all campaign report
    case reportActionType.GET_ALL_CAMPAIGN_REPORT_REQ:
      return Object.assign({}, state, {
        status: action.status,
      });
    case reportActionType.GET_ALL_CAMPAIGN_REPORT_SUCC:
      return Object.assign({}, state, {
        status: action.status,
        all_campaign_report: action.result,
      });
    case reportActionType.GET_ALL_CAMPAIGN_REPORT_ERR:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error,
      });

      // campaign reports by id

      case reportActionType.GET_CAMP_REPORT_BY_ID_REQ:
      return Object.assign({}, state, {
        campaign_report_by_id: [],
        campaign_report_by_id_loading: true,
        campaign_report_by_id_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        campaign_report_by_id: action.payload,
        campaign_report_by_id_loading: false,
        campaign_report_by_id_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_ERR:
      return Object.assign({}, state, {
        campaign_report_by_id: [],
        campaign_report_by_id_loading: false,
        campaign_report_by_id_error: action.payload,
      });



      // campaign reports by id descriptive

      case reportActionType.GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_REQ:
      return Object.assign({}, state, {
        campaign_report_by_id_descriptive: [],
        campaign_report_by_id_descriptive_loading: true,
        campaign_report_by_id_descriptive_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_SUCCESS:
      return Object.assign({}, state, {
        campaign_report_by_id_descriptive: action.payload,
        campaign_report_by_id_descriptive_loading: false,
        campaign_report_by_id_descriptive_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_DESCRIPTIVE_ERR:
      return Object.assign({}, state, {
        campaign_report_by_id_descriptive: [],
        campaign_report_by_id_descriptive_loading: false,
        campaign_report_by_id_descriptive_error: action.payload,
      });


      // campaign reports by id multiple choice

      case reportActionType.GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_REQ:
      return Object.assign({}, state, {
        campaign_report_by_id_multiple_choice: [],
        campaign_report_by_id_multiple_choice_loading: true,
        campaign_report_by_id_multiple_choice_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_SUCCESS:
      return Object.assign({}, state, {
        campaign_report_by_id_multiple_choice: action.payload,
        campaign_report_by_id_multiple_choice_loading: false,
        campaign_report_by_id_multiple_choice_error: null,
      });
    case reportActionType.GET_CAMP_REPORT_BY_ID_MULTIPLE_CHOICE_ERR:
      return Object.assign({}, state, {
        campaign_report_by_id_multiple_choice: [],
        campaign_report_by_id_multiple_choice_loading: false,
        campaign_report_by_id_multiple_choice_error: action.payload,
      });

      //GET ALL USER RATINGS CAMPAIGN REPORTS BY ID 
      case reportActionType.GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_REQ:
      return Object.assign({}, state, {
        all_user_ratings_campaign_report_by_id: [],
        all_user_ratings_campaign_report_by_id_loading: true,
        all_user_ratings_campaign_report_by_id_error: null,
      });
    case reportActionType.GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        all_user_ratings_campaign_report_by_id: action.payload,
        all_user_ratings_campaign_report_by_id_loading: false,
        all_user_ratings_campaign_report_by_id_error: null,
      });
    case reportActionType.GET_ALL_USER_RATINGS_CAMP_REPORT_BY_ID_ERR:
      return Object.assign({}, state, {
        all_user_ratings_campaign_report_by_id: [],
        all_user_ratings_campaign_report_by_id_loading: false,
        all_user_ratings_campaign_report_by_id_error: action.payload,
      });


      //GET ALL USER RATINGS DETAILS CAMPAIGN REPORTS BY ID 
      case reportActionType.GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_REQ:
      return Object.assign({}, state, {
        all_user_ratings_details_campaign_report_by_id: [],
        all_user_ratings_details_campaign_report_by_id_loading: true,
        all_user_ratings_details_campaign_report_by_id_error: null,
      });
    case reportActionType.GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        all_user_ratings_details_campaign_report_by_id: action.payload,
        all_user_ratings_details_campaign_report_by_id_loading: false,
        all_user_ratings_details_campaign_report_by_id_error: null,
      });
    case reportActionType.GET_ALL_USER_RATINGS_DETAILS_CAMP_REPORT_BY_ID_ERR:
      return Object.assign({}, state, {
        all_user_ratings_details_campaign_report_by_id: [],
        all_user_ratings_details_campaign_report_by_id_loading: false,
        all_user_ratings_details_campaign_report_by_id_error: action.payload,
      });
       //DEFAULT BEHAVIOR
    default:
        return state;
    }
  };
  export default report;